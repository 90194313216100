import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

import { SeoProps } from './models';

const Seo: FC<SeoProps> = ({
  lang,
  urls,
  seo: { title, description, keywords, externalHreflangs, additionalMeta, canonicalUrl },
}) => {
  const {
    siteSettings,
    brandSettings,
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    query SettingsQuery {
      siteSettings {
        siteName
        lang
        siteUrl
      }
      brandSettings {
        brandName
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteTitle = siteSettings.siteName || '';
  const titleTemplate = title === siteTitle ? '%s' : `%s | ${siteTitle}`;
  const siteAuthor = brandSettings.brandName;
  const siteLang = lang || siteSettings.lang;
  const meta = [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteAuthor,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    ...(keywords?.length
      ? [
          {
            name: 'keywords',
            content: keywords.join(', '),
          },
        ]
      : []),
    ...additionalMeta.map(({ key, value }) => ({ name: key, content: value })),
  ];

  const link = [
    ...urls.map(({ lang: hreflang, href }) => ({
      rel: 'alternate',
      href: `${siteUrl}${href}`,
      hreflang: hreflang.replace('_', '-'),
    })),
    ...externalHreflangs.map(({ key, value }) => ({
      rel: 'alternate',
      href: value,
      hreflang: key,
    })),
  ];

  return (
    <Helmet htmlAttributes={{ lang: siteLang }} {...{ title, titleTemplate, meta, link }}>
      {canonicalUrl ? (
        <link rel="canonical" href={`${siteSettings.siteUrl}${canonicalUrl}`} />
      ) : null}
    </Helmet>
  );
};

export default Seo;
